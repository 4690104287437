import { ClientError } from 'nice-grpc-web';
import { Status } from 'nice-grpc-common';

const internalErrorCodes = [
  Status.CANCELLED,
  Status.UNKNOWN,
  Status.ABORTED,
  Status.UNIMPLEMENTED,
  Status.INTERNAL,
  Status.UNAVAILABLE,
  Status.DATA_LOSS,
];
const isJson = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

function extractErrorMessage(error: ClientError): string {
  let generatedMessage = 'An error has occurred, but there is no message.';
  if (internalErrorCodes.includes(error.code)) {
    generatedMessage = 'Internal connection error: contact support if the problem persists.';
  } else if (isJson(error.details)) {
    const details = JSON.parse(error.details);
    if (details && Object.prototype.hasOwnProperty.call(details, 'message')) {
      generatedMessage = details.message;
    }
  } else if (error.details) {
    generatedMessage = error.details;
  }
  return generatedMessage;
}

export default extractErrorMessage;
