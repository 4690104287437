import { CurrentUser } from 'hiber-grpc/src/customer_compiled/currentuser';
import { OrganizationPermission, SupportPermission } from 'hiber-grpc/src/customer_compiled/permission';
import { defineAclRules } from 'vue-simple-acl';

export enum Rule {
  ASSIGNMENTS = 'ASSIGNMENTS',
  ASSIGN_ALARMS = 'ASSIGN_ALARMS',
  ASSIGN_DEVICE_TYPE = 'ASSIGN_DEVICE_TYPE',
  VIEW_DEVICES = 'VIEW_DEVICES',
  EDIT_DEVICE = 'EDIT_DEVICE',
  VIEW_TRANSFERS = 'VIEW_TRANSFERS',
  SEND_TRANSFER = 'SEND_TRANSFER',
  ASSIGN_TAGS = 'ASSIGN_TAGS',
  MANAGE_TAGS = 'MANAGE_TAGS',
  VIEW_ALARMS = 'VIEW_ALARMS',
  MANAGE_ALARMS = 'MANAGE_ALARMS',
  MANAGE_USERS = 'MANAGE_USERS',
  EXPORT = 'EXPORT',
  SECURE_NOTES = 'SECURE_NOTES',
  TRANSMISSION_INTERVAL = 'TRANSMISSION_INTERVAL',
  DEVICE_SLA = 'DEVICE_SLA',
  DEVICE_LIFECYCLE = 'DEVICE_LIFECYCLE',
  DEVICE_TIMEZONE = 'DEVICE_TIMEZONE',
  CREATE_ORGANIZATION = 'CREATE_ORGANIZATION',
  UPDATE_LOGO = 'UPDATE_LOGO',
  VIEW_USERS = 'VIEW_USERS',
  PUBLISHERS = 'PUBLISHERS',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  VIEW_ASSETS = 'VIEW_ASSETS',
  UPDATE_ASSETS = 'UPDATE_ASSETS',
  TOKEN_LOGIN = 'TOKEN_LOGIN',
}

export type RuleType = typeof Rule;

export const rules = (): ReturnType<typeof defineAclRules> =>
  defineAclRules<CurrentUser>((setRule) => {
    setRule(Rule.TOKEN_LOGIN, () => import.meta.env.MODE?.toLowerCase() === 'development');
    setRule(
      Rule.EDIT_DEVICE,
      (user) =>
        user &&
        (!!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.MODEMS_UPDATE) ||
          !!user.currentOrganizationPermissions?.includeAll),
    );
    setRule(
      Rule.VIEW_DEVICES,
      (user) =>
        user &&
        (!!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.MODEMS) ||
          !!user.currentOrganizationPermissions?.includeAll),
    );
    setRule(
      Rule.VIEW_TRANSFERS,
      (user) =>
        user &&
        (!!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.TRANSFERS) ||
          !!user.currentOrganizationPermissions?.includeAll),
    );
    setRule(
      Rule.SEND_TRANSFER,
      (user) =>
        user &&
        (!!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.TRANSFERS_SEND) ||
          !!user.currentOrganizationPermissions?.includeAll),
    );
    setRule(
      Rule.EXPORT,
      (user) =>
        user &&
        (!!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.EXPORT) ||
          !!user.currentOrganizationPermissions?.includeAll),
    );
    setRule(
      Rule.CREATE_ORGANIZATION,
      (user) =>
        user &&
        (!!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.ORGANIZATION_CREATE) ||
          !!user.currentOrganizationPermissions?.includeAll),
    );
    setRule(
      Rule.UPDATE_LOGO,
      (user) =>
        user &&
        (!!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.ORGANIZATION_UPDATE) ||
          !!user.currentOrganizationPermissions?.includeAll),
    );
    setRule(
      Rule.ASSIGNMENTS,
      (user) =>
        !!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.ASSIGNMENTS) ||
        !!user.currentOrganizationPermissions?.includeAll,
    );
    setRule(
      Rule.ASSIGN_ALARMS,
      (user) =>
        user &&
        (!!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.MODEMS_ALARMS) ||
          !!user.currentOrganizationPermissions?.includeAll),
    );
    setRule(
      Rule.VIEW_ALARMS,
      (user) =>
        user &&
        (!!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.MODEMS_ALARMS) ||
          !!user.currentOrganizationPermissions?.includeAll),
    );
    setRule(
      Rule.MANAGE_ALARMS,
      (user) =>
        user &&
        (!!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.MODEMS_ALARMS_UPDATE) ||
          !!user.currentOrganizationPermissions?.includeAll),
    );
    setRule(
      Rule.ASSIGN_TAGS,
      (user) =>
        user &&
        (!!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.TAGS_MANAGE) ||
          !!user.currentOrganizationPermissions?.includeAll),
    );
    setRule(
      Rule.MANAGE_TAGS,
      (user) =>
        user &&
        (!!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.TAGS_MANAGE) ||
          !!user.currentOrganizationPermissions?.includeAll),
    );
    setRule(
      Rule.VIEW_USERS,
      (user) =>
        user &&
        (!!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.USERS) ||
          !!user.currentOrganizationPermissions?.includeAll),
    );
    setRule(
      Rule.VIEW_ASSETS,
      (user) =>
        user &&
        (!!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.ASSETS) ||
          !!user.currentOrganizationPermissions?.includeAll),
    );
    setRule(
      Rule.UPDATE_ASSETS,
      (user) =>
        user &&
        (!!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.ASSETS_UPDATE) ||
          !!user.currentOrganizationPermissions?.includeAll),
    );
    setRule(
      Rule.MANAGE_USERS,
      (user) =>
        user &&
        (!!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.USERS_MANAGE) ||
          !!user.currentOrganizationPermissions?.includeAll),
    );

    setRule(
      Rule.SECURE_NOTES,
      (user) =>
        user &&
        (!!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.MODEMS_SECURE_NOTES) ||
          !!user.currentOrganizationPermissions?.includeAll),
    );
    setRule(
      Rule.TRANSMISSION_INTERVAL,
      (user) =>
        user && !!user.supportPermissions?.include?.includes(SupportPermission.MANAGE_DEVICE_TRANSMISSION_INTERVAL),
    );
    setRule(
      Rule.DEVICE_SLA,
      (user) => user && !!user.supportPermissions?.include?.includes(SupportPermission.MANAGE_DEVICE_SLA),
    );
    setRule(
      Rule.DEVICE_LIFECYCLE,
      (user) => user && !!user.supportPermissions?.include?.includes(SupportPermission.DEVICE_VIEW_ALL_LIFECYCLES),
    );
    setRule(
      Rule.DEVICE_TIMEZONE,
      (user) => user && !!user.supportPermissions?.include?.includes(SupportPermission.MANAGE_DEVICE_TIME_ZONE),
    );
    setRule(
      Rule.ASSIGN_DEVICE_TYPE,
      (user) => user && !!user.supportPermissions?.include?.includes(SupportPermission.MANAGE_DEVICE_TYPE),
    );
    setRule(
      Rule.CUSTOMER_SUPPORT,
      (user) => user && !!user.supportPermissions?.include?.includes(SupportPermission.CUSTOMER_SUPPORT),
    );
    setRule(
      Rule.PUBLISHERS,
      (user) =>
        user &&
        (!!user.currentOrganizationPermissions?.include?.includes(OrganizationPermission.PUBLISHERS) ||
          !!user.currentOrganizationPermissions?.includeAll),
    );
  });
